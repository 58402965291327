

#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

:root {
  --nabis-base-font-size: 16px;
  /* 1.125rem = 18px */
  --nabis-base-padding-x: 1.125rem;
  --nabis-base-padding-y: 1rem;
  --nabis-font-family: 'Inter', -apple-system, 'system-ui', 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;
  --nabis-font-line-height: 1.35em;
  --nabis-font-weight-bold: 600;
  --nabis-font-weight-medium: 400;
  --nabis-font-title--page: var(--nabis-font-weight-bold) 1.75rem / var(--nabis-font-line-height)
    var(--nabis-font-family);
  --nabis-font-title--container: var(--nabis-font-weight-bold) 1.375rem / var(--nabis-font-line-height)
    var(--nabis-font-family);
  --nabis-font-heading--section: var(--nabis-font-weight-bold) 1rem / var(--nabis-font-line-height)
    var(--nabis-font-family);
  --nabis-font-heading--caps: var(--nabis-font-weight-bold) 1rem / var(--nabis-font-line-height)
    var(--nabis-font-family);
  --nabis-font-body: var(--nabis-font-weight-medium) 0.875rem / var(--nabis-font-line-height) var(--nabis-font-family);
  --nabis-font-caption: var(--nabis-font-weight-medium) 0.875rem / var(--nabis-font-line-height)
    var(--nabis-font-family);
  --nabis-font-badge: var(--nabis-font-weight-bold) 0.6875rem / var(--nabis-font-line-height) var(--nabis-font-family);
  /* Colors */
  --nabis-color-background-global-light: #f2f2f2;
  --nabis-color-background-global-dark: #1a1b1c;
}
/*
body {
  margin: 0;
  font: var(--nabis-font-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

* {
  font-family: var(--nabis-font-family);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input {
  font: var(--nabis-font-body);
}

img {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  -webkit-user-select: none; /* Safari */
  user-select: none; /* Standard */
  max-width: 100%;
}

a {
  color: #0eae38;
}

a:hover {
  color: #16ab39;
}

button {
  font-size: 0.875rem;
}

/* FOR STICKY TABLE HEADERS */
.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transform: translate3d(-64px, 21px, 0px) !important;
  transition: none !important;
  animation: none !important;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  transform: translate3d(166px, 38px, 0px);
  transition: none !important;
  animation: none !important;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
  cursor: pointer;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #868e96;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap;
}

.react-datepicker-wrapper input {
  width: 100% !important;
}
#order-form-date-picker > .react-datepicker-wrapper {
  display: block;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  padding: 5px !important;
}
.react-datepicker__time-list {
  padding: 0px !important;
}
.react-datepicker__day--selected,
.react-datepicker__month--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__month--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month--in-range {
  background-color: #0eae38 !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: #0eae38 !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #0eae38 !important;
}

.ui.search .prompt {
  border-radius: 0.28571429rem;
}
.card button {
  color: #0eae38;
  cursor: pointer;
}

.card button:focus {
  outline: none;
}
/* .ui.input input:focus,
.ui.input.focus input {
  border-color: #0eae38;
} */
.ui.selection.active.dropdown {
  border-color: #0eae38 !important;
}
.ui.selection.active.dropdown .menu {
  border-color: #0eae38 !important;
}
/* .ui.form input:not([type]):focus,
.ui.form input[type='date']:focus,
.ui.form input[type='datetime-local']:focus,
.ui.form input[type='email']:focus,
.ui.form input[type='file']:focus,
.ui.form input[type='number']:focus,
.ui.form input[type='password']:focus,
.ui.form input[type='search']:focus,
.ui.form input[type='tel']:focus,
.ui.form input[type='text']:focus,
.ui.form input[type='time']:focus,
.ui.form input[type='url']:focus {
  border-color: #0eae38;
} */
.ui.steps .step.active .title {
  color: #0eae38;
}

.modals.dimmer {
  /* For making sure that the modal does not collide with navbar */
  padding-top: calc(70px + 35px);
  /* This synchronizes the mui modal and chakra ui modal z-indexes so that chakra ui's don't automatically take precedence */
  z-index: var(--chakra-zIndices-modal);
}

@media (max-width: 768px) {
  .modals.dimmer {
    /* On mobile or tablet, for making sure that the modal does not collide with navbar */
    padding-top: calc(70px + 20px);
  }
}
